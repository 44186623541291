import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

export default class GlobalCommunicationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return this.canSeeFeatures && this.featureFlags.isEnabled('emergency-notifications-templates-garaje');
  }

  get canSeeFeatures(): boolean {
    if (!this.currentAdmin.isGlobalAdmin) {
      return false;
    }

    return !!this.state.features?.canAccessEmergencyNotifications;
  }

  get canEditFeatures(): boolean {
    return this.canSeeFeatures;
  }
}
