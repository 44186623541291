import { A, isArray } from '@ember/array';
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { timeout } from 'ember-concurrency';
import type MomentService from 'ember-moment/services/moment';
import config from 'garaje/config/environment';
import type CompanyModel from 'garaje/models/company';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type { KioskForceUpgradeMessage } from 'garaje/models/location';
import type ProtectedController from 'garaje/pods/protected/controller';
import type AuthzService from 'garaje/services/authz';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import { CURRENT_LOCATION_KEY } from 'garaje/services/context-switcher';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlowService from 'garaje/services/flow';
import type LocalStorageService from 'garaje/services/local-storage';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import type LocationsService from 'garaje/services/locations';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import type SyncingService from 'garaje/services/syncing';
import type UserDocumentService from 'garaje/services/user-document';
import { configureBugsnag } from 'garaje/utils/configure-bugsnag';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import normalizeResponse from 'garaje/utils/normalize-response';
import zft from 'garaje/utils/zero-for-tests';
import moment from 'moment-timezone';
import rome from 'rome';

import type { CurrentZoneRouteModel } from '../current-zone/route';
import type VisitorsEntriesController from '../visitors/entries/controller';
import type VisitorsInvitesIndexController from '../visitors/invites/index/controller';

export default class CurrentLocationRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentLocation: CurrentLocationService;
  @service declare contextSwitcher: ContextSwitcherService;
  @service declare metrics: MetricsService;
  @service declare moment: MomentService;
  @service declare localStorage: LocalStorageService;
  @service declare locations: LocationsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare state: StateService;
  @service declare syncing: SyncingService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service('flow') declare employeeScreeningFlowService: FlowService;
  @service('user-document') declare userDocumentService: UserDocumentService;
  @service declare featureConfig: FeatureConfigService;
  @service declare authz: AuthzService;

  model(): LocationModel | undefined {
    const { currentCompany, currentLocation } = this.state;

    if (!currentCompany) {
      return;
    }

    return currentLocation;
  }

  async afterModel(location: LocationModel): Promise<LocationModel | null | undefined> {
    // do not run location based set up if we are loading into a zone
    if ((<CurrentZoneRouteModel>this.modelFor('current-zone')).currentZone) return;

    if (!location) {
      void this.router.transitionTo('profile');
      return;
    }

    set(this.currentLocation, 'content', location);
    // TODO: remove setting on state.currentLocation once all places that refer
    // to it use this.modelFor('current-location')
    set(this.state, 'currentLocation', location);
    this.metrics.trackEvent('Setting currentLocation in state', { location });

    // store the current_location in localStorage to avoid needing to query the
    // skinnyLocations again in the future.
    this.localStorage.setItem(CURRENT_LOCATION_KEY, location.id);
    const { currentLocation, currentUser, currentCompany, vrSubscription } = this.state;

    this.currentLocation.setupPubnub(this);

    // Do not await / block these calls here
    void this.currentLocation.checkDevicesStatus.perform();
    void this.syncing.loadSyncingHistory();

    if (this.currentAdmin.isAdminLike) {
      // we need to load this so that the visual compliance ability can determine if the current admin is a visual compliance contact at the current location. Only front-desk admins, location admins, and global admins have access to load the visual compliance config, otherwise it will 403
      await currentLocation.visualComplianceConfiguration;
    }

    configureBugsnag({ currentLocation, currentUser, currentCompany });
    this._configureMomentTimeZone(currentLocation);
    this._markRecentlyVisitedLocation(currentLocation, currentCompany);

    // eslint-disable-next-line ember/no-controller-access-in-routes
    const { isBoss } = <{ isBoss: boolean }>(<unknown>this.controllerFor('application'));
    this.currentLocation.setTrackingData(isBoss);

    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;

    // Load chameleon script into the head of the document
    if (this.featureFlags.isEnabled('growth_chameleon_identify') && (isGlobalAdmin || isLocationAdmin)) {
      if (this.currentLocation !== undefined) {
        this.currentLocation.setupChameleon();
      }
    }

    if (isPresent(vrSubscription)) {
      this._vrAsyncSetup(currentLocation);
    }

    return currentLocation;
  }

  _vrAsyncSetup(location: LocationModel): void {
    void this.state.loadFlows({ reload: false }); // Preload flows on a non blocking way
    void location.kioskForceUpgradeMessage().then(({ data: { attributes } }) => {
      if (attributes) {
        const { body, title, 'dismissable-on-garaje': dismissable } = attributes;
        this.metrics.trackEvent('CTA Viewed', {
          cta_id: 'force_upgrade_banner',
          cta_type: 'banner',
          cta_clickable_type: 'none',
          cta_title: title,
          cta_body: body,
        });
        const kioskForceUpgradeMessage: KioskForceUpgradeMessage = { body, title, dismissable };
        // eslint-disable-next-line ember/no-controller-access-in-routes
        const protectedController = <ProtectedController>this.controllerFor('protected');
        set(protectedController, 'model', { kioskForceUpgradeMessage });
      }
    });
  }

  titleToken(location: LocationModel): string | undefined {
    const { currentCompany } = this.state;
    if (location && currentCompany.hasMany('locations').ids().length > 1) {
      return location.nameWithCompanyName;
    }
    return;
  }

  // TODO: @heroiceric
  // Do we really need to set the timezone for both of these moments?
  _configureMomentTimeZone({ timezone }: LocationModel): void {
    this.moment.changeTimeZone(timezone);

    // this sets global moment for scenarios  where we do moment()
    moment.tz.setDefault(timezone);

    // Configures rome calendar to use our moment instance
    rome.use(moment);
  }

  _markRecentlyVisitedLocation(location: LocationModel, company: CompanyModel): void {
    if (config.environment !== 'test') {
      this.locations.markAsRecentlyVisited(location.id, company.id);
    }
  }

  async pushEntry(payload: { v2entry: Record<string, unknown>; event: string }): Promise<void> {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const entriesController = <VisitorsEntriesController>this.controllerFor('visitors.entries');
    const dashDate = entriesController.dateWithDefault;
    const query = entriesController.query;

    const normalizedPayload = normalizeResponse(this.store, 'entry', payload.v2entry);
    const entry = <EntryModel>this.store.push(normalizedPayload);

    // This entry was a duplicate and not pushed onto the store
    // bailing early so the rest of this function doesn't :boom:
    if (!entry) {
      return;
    }
    if (this.currentLocation.owns(entry)) {
      const flowNames = entriesController.selectedFlowModels.map((flow) => flow.name);
      if (flowNames.length && !flowNames.includes(entry.flowName)) {
        return;
      }

      const newEntryTime = moment(entry.signInTime).format('YYYY-MM-DD');

      if (entriesController.model?.entries) {
        const entries = entriesController.model.entries;

        if (
          payload.event === 'sign_in' &&
          newEntryTime === dashDate &&
          entries &&
          !query &&
          !entries.find((e) => e.id === entry.id)
        ) {
          void entriesController.pollEventReports.perform();
          entriesController.calculateEntriesCount.call(entriesController, 1);

          if (this.abilities.can('see entry log for desk')) {
            try {
              // The reservation has not been created on the backend when the pubnub event is received so we must wait
              await timeout(zft(4000));
              await entry.reservations;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Error fetching reservation', e);
            }
          }

          const { currentLocation } = this.state;
          if (currentLocation.capacityLimitEnabled) {
            void fetchCapacity(this.store, currentLocation, entry.signedInAt);
          }

          const parent =
            entry.belongsTo('groupParent').id() && entries.find((e) => e.id === entry.belongsTo('groupParent').id());

          if (parent) {
            /*
               Entries beloging to a group parent, should go after
               it. We need this workaround since the group parent gets
               pushed first and the children some seconds after.
               */
            const parentIndex = entries.indexOf(parent);
            const offset = Number(entry.fullName.split('+')[1] || 1);

            A(entries).insertAt(parentIndex + offset, entry);
          } else {
            A(entries).unshiftObject(entry);
          }
        }
      }
    } else {
      /*
         Right now we are not doing anything with entries from the
         other locations I have access to. We might want to do
         something in the near future.
         */
      entry.unloadRecord();
    }
  }

  newInvite(payload: { type: string; invite: Record<string, unknown> }): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const preRegisteredController = <VisitorsInvitesIndexController>this.controllerFor('visitors.invites.index');
    const dashDate = preRegisteredController.dateWithDefault;

    const normalizedPayload = normalizeResponse(this.store, 'invite', payload.invite);
    const invite = <InviteModel>this.store.push(normalizedPayload);

    // This invite was a duplicate and not pushed onto the store
    // bailing early so the rest of this function doesn't :boom:
    if (!invite) {
      return;
    }

    if (this.currentLocation.owns(invite)) {
      const newInviteTime = moment(invite.expectedArrivalTime).format('YYYY-MM-DD');

      if (isArray(preRegisteredController.model?.invites)) {
        const invites = preRegisteredController.model.invites;

        if (payload.type === 'new_invite' && newInviteTime === dashDate && !invites.findBy('id', invite.id)) {
          /*
           * In theory we shouldn't need the check based in id since
           * addObject doesn't add the item if it is in the list,
           * but it looks like the object comparison fails some
           * times.
           */
          invites.addObject(invite);
          preRegisteredController.invitesCount++;
        }
      }
    } else {
      invite.unloadRecord();
    }
  }

  deleteInvite(payload: { invite_id: string }): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const preRegisteredController = <VisitorsInvitesIndexController>this.controllerFor('visitors.invites.index');
    const deletedInvite = this.store.peekRecord('invite', payload.invite_id);

    if (isArray(preRegisteredController.model?.invites) && deletedInvite) {
      const invites = preRegisteredController.model.invites;
      invites.removeObject(deletedInvite);
      deletedInvite.unloadRecord();
      preRegisteredController.invitesCount--;
    }
  }
}
