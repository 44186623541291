/* eslint-disable ember/use-ember-get-and-set */
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type { Audiences, DeliveryMethod, Location, MessageCategory, Template } from '@envoy/components-communication';
import { TemplateForm } from '@envoy/components-communication';
import type { DropdownOptionType } from '@envoy/polarwind-react';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type { DetailedChangeset } from 'ember-changeset/types';
import { type TaskForAsyncTaskFunction } from 'ember-concurrency';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type EmployeeGroupModel from 'garaje/models/employee-group';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type CommunicationsService from 'garaje/services/communications-service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ReactRendererService from 'garaje/services/react-renderer';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import {
  ANNOUNCEMENT_MESSAGE_VARIABLES,
  ANNOUNCEMENT_TEMPLATE_EMPLOYEE_AUDIENCES,
  ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES,
  ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES_M2,
} from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { Root } from 'react-dom/client';

type SelectOptionType = {
  label: string;
  value: string;
};

type AnnouncementTemplateFormArgsType = {
  changeset: DetailedChangeset<AnnouncementTemplateModel>;
  saveTask: TaskForAsyncTaskFunction<unknown, () => Promise<void>>;
  deleteTask?: TaskForAsyncTaskFunction<unknown, () => Promise<void>>;
};

export default class AnnouncementTemplateForm extends Component<AnnouncementTemplateFormArgsType> {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare reactRenderer: ReactRendererService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare communicationsService: CommunicationsService;

  @tracked employeeAudienceOptions: SelectOptionType[] = ANNOUNCEMENT_TEMPLATE_EMPLOYEE_AUDIENCES;
  @tracked messageVariables: SelectOptionType[] = ANNOUNCEMENT_MESSAGE_VARIABLES;
  @tracked shouldShowCategoryCreateModal: boolean = false;
  @tracked categoryChangeset!: DetailedChangeset<AnnouncementTemplateCategoryModel>;

  private root?: Root;

  get categoryOptions(): AnnouncementTemplateCategoryModel[] {
    return this.store.peekAll('announcement-template-category').sortBy('name');
  }

  get authorizedLocationIds(): string[] {
    const userLocations = this.state.currentUser.locationRoles.toArray();
    return userLocations.filter((l) => l.isLocationAdmin).map((l) => l.belongsTo('location').id());
  }

  // Checks if the user can select custom employees
  get showCustomEmployeeSelect(): boolean {
    return (
      // Remove the SCIM group check later
      this.communicationsService.canSelectScimGroups &&
      this.featureFlags.isEnabled('communications-template-form-custom-employees-web')
    );
  }

  get visitorAudienceOptions(): SelectOptionType[] {
    if (this.communicationsService.canMultiSelectAudiences) {
      return ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES_M2;
    }

    return ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES;
  }

  // Checks if the user has access to edit this template
  get canEdit(): boolean {
    const isNew = this.args.changeset.isNew as unknown as boolean;

    if (isNew || this.currentAdmin.isGlobalAdmin) {
      return true;
    }

    const authorizedLocationIds = this.authorizedLocationIds;
    const locations = this.args.changeset.locations.toArray();

    return locations.every((l) => authorizedLocationIds.includes(l.id));
  }

  get values(): Template {
    const employeeGroups =
      (this.args.changeset._content.hasMany('employeeGroups').value()?.toArray() as EmployeeGroupModel[]) || [];
    const customSelections: (DropdownOptionType & { type: 'GROUP' })[] = employeeGroups.map((g) => ({
      label: g.displayName,
      value: g.id.toString(),
      type: 'GROUP',
    }));

    return {
      id: this.args.changeset.id,
      name: this.args.changeset.name,
      title: this.args.changeset.title,
      defaultChannels: this.args.changeset.defaultChannels as DeliveryMethod[],
      category: this.args.changeset._content.belongsTo('announcementTemplateCategory').id(),
      defaultEmployeeAudiences: this.args.changeset.defaultEmployeeAudiences.map((a) => a.toUpperCase()) as Audiences[],
      defaultVisitorAudiences: this.args.changeset.defaultVisitorAudiences.map((a) => a.toUpperCase()) as Audiences[],
      locations: this.args.changeset._content.hasMany('locations').ids(),
      customSelections,
      locationNames: this.args.changeset.locationNames,
      published: this.args.changeset.published,
      message: this.args.changeset.message,
      markAsSafe: this.args.changeset.markAsSafe,
    };
  }

  formatAudienceValue(audience?: Audiences | Audiences[]): Audiences[] {
    if (!audience) return [];

    if (Array.isArray(audience)) {
      return audience.map((a) => a.toLowerCase()) as Audiences[];
    }

    return [audience.toLowerCase() as Audiences];
  }

  async getSkinnyLocations(): Promise<SkinnyLocationModel[]> {
    const locationsInStore = this.store.peekAll('skinny-location').toArray();

    if (locationsInStore?.length) {
      return locationsInStore;
    }

    return (await this.skinnyLocations.loadAllTask.perform())?.toArray() || [];
  }

  async getLocations(): Promise<Location[]> {
    const locations = await this.getSkinnyLocations();

    if (this.currentAdmin.isGlobalAdmin) {
      return locations.filter((l) => !l.disabledToEmployeesAt);
    }

    return locations.filter((l) => this.authorizedLocationIds.includes(l.id) && !l.disabledToEmployeesAt);
  }

  @action
  updateDefaultChannels(defaultChannels: SelectOptionType[]): void {
    const selectedChannels = defaultChannels.map((c) => c.value);
    this.args.changeset.set('defaultChannels', selectedChannels);
  }

  @action
  async updateLocations(locationIds: string[]): Promise<void> {
    const skinnyLocations = await this.getSkinnyLocations();
    const selectedSkinnyLocations = skinnyLocations.filter((l) => locationIds.includes(l.id));

    const selectedLocations = await Promise.all(
      selectedSkinnyLocations.map(async (skinnyLocation) => {
        return (await skinnyLocation.realLocation()) as LocationModel;
      }),
    );

    this.args.changeset.set('locations', selectedLocations);
    this.args.changeset.set(
      'locationNames',
      selectedLocations.map((l) => l.name),
    );
  }

  async createCategory(name: string): Promise<MessageCategory | undefined> {
    try {
      const announcementTemplateCategory = this.store.createRecord('announcement-template-category', {
        name,
        company: this.state.currentCompany,
      });

      const createdCategory = await announcementTemplateCategory.save();

      this.args.changeset.set('announcementTemplateCategory', createdCategory);

      this.flashMessages.showAndHideFlash('success', 'Saved!');

      return {
        id: createdCategory.id,
        name: createdCategory.name,
      };
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
    return undefined;
  }

  async onDelete(): Promise<void> {
    await this.args.deleteTask?.perform();
  }

  async onSave(values: Partial<Template & { selectedEmployees: string[]; selectedGroups: string[] }>): Promise<void> {
    const category = this.categoryOptions.find((c) => c.id === values.category);
    const employeeAudiences = this.formatAudienceValue(values.defaultEmployeeAudiences);
    const visitorAudiences = this.formatAudienceValue(values.defaultVisitorAudiences);

    if (values.selectedGroups?.length) {
      employeeAudiences.push('employee_group' as Audiences);
    }

    // if (values.selectedEmployees?.length) {
    //   employeeAudiences.push('custom_employee' as Audiences);
    // }

    if (category) this.args.changeset.set('announcementTemplateCategory', category);

    await this.updateLocations(values.locations || []);

    this.args.changeset.setProperties({
      name: values.name,
      title: values.title,
      defaultChannels: values.defaultChannels,
      defaultEmployeeAudiences: employeeAudiences,
      defaultVisitorAudiences: visitorAudiences,
      defaultEmployeeGroups: values.selectedGroups,
      // defaultEmployees: values.selectedEmployees,
      published: values.published || true,
      message: values.message,
      markAsSafe: values.markAsSafe || false,
    });

    await this.args.saveTask.perform();
  }

  @action
  showCategoryCreateModal(): void {
    this.shouldShowCategoryCreateModal = true;
  }

  @action
  closeCategoryCreateModal(): void {
    this.shouldShowCategoryCreateModal = false;
  }

  @action
  injectComponent(): void {
    this.root = this.reactRenderer.render(TemplateForm, 'react__communications__template-form', {
      service: {
        ...this.communicationsService,
        analytics: this.communicationsService.analytics,
        canMultiSelectAudiences: this.communicationsService.canMultiSelectAudiences,
        canSelectScimGroups: this.communicationsService.canSelectScimGroups,
        showCustomEmployeeSelect: this.showCustomEmployeeSelect,
        showEmployeeSelect: this.communicationsService.showEmployeeSelect,
        showVisitorSelect: this.communicationsService.showVisitorSelect,
        getAudiences: () =>
          Promise.resolve({
            employees: this.employeeAudienceOptions.map((a) => ({ id: a.value as Audiences })),
            visitors: this.visitorAudienceOptions.map((a) => ({ id: a.value as Audiences })),
          }),
        getCategories: () => Promise.resolve(this.categoryOptions.map((c) => ({ id: c.id, name: c.name }))),
        getEmployeeGroupOptions: (query: string, page: number) =>
          this.communicationsService.getEmployeeGroupOptions(query, page),
        getEmployeeOptions: (query: string, page: number) => this.communicationsService.getEmployeeOptions(query, page),
        getLocations: () => this.getLocations(),
        getMessageVariables: () => Promise.all(this.messageVariables),
      },
      values: this.values,
      canEditForm: this.canEdit,
      templateId: this.args.changeset.id,
      onSave: (values) => this.onSave(values),
      onDelete: this.args.deleteTask?.perform ? () => this.onDelete() : undefined,
      createCategory: (name) => this.createCategory(name),
    });
  }

  @action
  destroyComponent(): void {
    if (this.root) {
      this.reactRenderer.unmount(this.root);
      this.root = undefined;
    }
  }
}
