import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

export default class Communication extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return (
      this.canSeeFeatures &&
      this.canEditFeatures &&
      this.featureFlags.isEnabled('emergency-notifications-templates-garaje')
    );
  }

  get canVisitMessages(): boolean {
    return this.canVisit && this.featureFlags.isEnabled('emergency-notifications-dashboard-parity');
  }

  get canSeeFeatures(): boolean {
    return !!this.state.features?.canAccessEmergencyNotifications;
  }

  get canEditFeatures(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return this.canSeeFeatures && isPresent(_intersection(CAN_VISIT_ROLES, roles));
  }

  get canAccessEmployeesEmergencyFeatures(): boolean {
    return !!this.state.features?.canAccessEmergencyNotificationsEmployees;
  }

  get canAccessVisitorsEmergencyFeatures(): boolean {
    return !!this.state.features?.canAccessEmergencyNotificationsVisitors;
  }

  get canViewMultiSelectDropdowns(): boolean {
    return this.featureFlags.isEnabled('emno-recipient-groups-message-web');
  }
}
