import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
import type AccessEventModel from 'garaje/models/access-event';
import type ChameleonService from 'garaje/services/chameleon';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

import type WorkplaceAccessEventsController from './controller';

export interface WorkplaceAccessEventsRouteParams {
  startDate: string | null;
  endDate: string | null;
  date: string | null;
  query: string;
  filter: string;
  refresh?: string;
  sort: string;
}

// TODO: Update this
export interface WorkplaceAccessEventsRouteModel {
  currentLocationId: string;
  date: string | null;
  accessEvents: AccessEventModel[];
  filter: string;
  query: string;
  sort: string;
}

export default class WorkplaceAccessEventsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare chameleon: ChameleonService;

  titleToken = 'Access Events';

  memoizedModel: WorkplaceAccessEventsRouteModel | null = null;

  queryParams = {
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
    date: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
    refresh: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  beforeModel(transition: Transition): void {
    if (this.abilities.cannot('visit workplace')) {
      this.router.transitionTo('unauthorized');
    }
    const queryParams: { date?: string | null; startDate?: string | null; endDate?: string | null } = {};
    const dateParam = transition.to.queryParams['date'];
    const startDateParam = transition.to.queryParams['startDate'];
    const endDateParam = transition.to.queryParams['endDate'];
    const hasInvalidDateParam = isPresent(dateParam) && !moment(dateParam).isValid();
    const hasInvalidStartDateParam = isPresent(startDateParam) && !moment(startDateParam).isValid();
    const hasInvalidEndDateParam = isPresent(endDateParam) && !moment(endDateParam).isValid();
    const isDateInvalid = hasInvalidDateParam || hasInvalidStartDateParam || hasInvalidEndDateParam;

    if (hasInvalidDateParam) queryParams.date = null;
    if (hasInvalidStartDateParam) queryParams.startDate = null;
    if (hasInvalidEndDateParam) queryParams.endDate = null;
    if (isDateInvalid) void this.router.transitionTo('workplace.access-events', { queryParams });
  }

  // TODO: add additional field to route params
  async model({
    startDate,
    endDate,
    date,
    query,
    filter,
    sort,
  }: WorkplaceAccessEventsRouteParams): Promise<WorkplaceAccessEventsRouteModel> {
    const { currentLocation } = this.state;
    const accessEvents: AccessEventModel[] = [];

    if (query && this.memoizedModel && this.memoizedModel.currentLocationId === currentLocation.id) {
      return Object.assign(this.memoizedModel, { startDate, endDate, date, query, filter, sort, accessEvents });
    }

    const currentLocationId = currentLocation.id;

    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_VIEWED');
    this.chameleon.trackDashboardPageEvent('ACCESS_EVENT_LOG_VIEWED');

    return hash({
      currentLocationId,
      startDate,
      endDate,
      date,
      accessEvents,
      filter,
      query,
      sort,
    });
  }

  afterModel(model: WorkplaceAccessEventsRouteModel): void {
    this.memoizedModel = model;
  }

  setupController(
    controller: WorkplaceAccessEventsController,
    model: WorkplaceAccessEventsRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.setStartAndEndTime();
  }

  resetController(controller: WorkplaceAccessEventsController, isExiting: boolean): void {
    if (isExiting) {
      controller.connectedTenants = null;
      controller.clearSearch();
      controller.startDate = null;
      controller.endDate = null;
      controller.date = '';
    }
  }
}
