import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type { TaskInstance } from 'ember-concurrency';
import type AccessEventModel from 'garaje/models/access-event';
import type { Field } from 'garaje/models/location';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type MetricsService from 'garaje/services/metrics';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import _intersection from 'lodash/intersection';
import { reads } from 'macro-decorators';

import type WorkplaceAccessEventsController from '../controller';

import type { WorkplaceAccessEventsIndexRouteModel } from './route';

export interface AccessEventsDashboardField extends Field {
  sort?: string;
  show?: boolean;
}

export default class WorkplaceAccessEventsIndexController extends Controller {
  declare model: WorkplaceAccessEventsIndexRouteModel;

  @service declare currentLocation: CurrentLocationService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @controller('workplace.access-events') accessEvents!: WorkplaceAccessEventsController;

  @reads('entries.isToday') isToday!: WorkplaceAccessEventsController['isToday'];
  @reads('entries.isAfterToday') isAfterToday!: WorkplaceAccessEventsController['isAfterToday'];

  get sortField(): string {
    return this.accessEvents.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.accessEvents.sort.startsWith('-') ? 'desc' : 'asc';
  }

  get menuHeight(): string {
    // Keep a minimum of 13px space from the table
    return `${document.querySelector('.employeesTable-header')!.clientHeight + 13}px`;
  }

  get isSelectAllIndeterminate(): boolean {
    const selectedCount = this.accessEvents.selectedAccessEvents.length;
    const totalAccessEvents = this.accessEvents.relevantAccessEvents.length;
    return selectedCount > 0 && selectedCount !== totalAccessEvents;
  }

  get allAccessEventsSelected(): boolean {
    const selectedCount = this.accessEvents.selectedAccessEvents.length;
    const totalAccessEvents = this.accessEvents.relevantAccessEvents.length;
    return selectedCount === totalAccessEvents;
  }

  @action
  clearAll(): void {
    return this.accessEvents.clearAll();
  }

  @action
  selectedDidChange(selected: AccessEventModel[]): void {
    return this.accessEvents.selectedDidChange(selected);
  }

  @action
  loadMore(): void | Promise<void> | TaskInstance<void> {
    return this.accessEvents.loadMore();
  }

  @action
  sortAccessEvents(field: string, direction: string): void {
    const accessEventsController = this.accessEvents;
    return accessEventsController.sortAccessEvents(field, direction);
  }

  @action
  selectAllAccessEvents(): void {
    return this.accessEvents.selectAllAccessEvents();
  }

  linkToRoute(): string {
    return 'workplace.access-event';
  }
}
