import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type { SinglePayload } from 'jsonapi/response';

import ApplicationSerializer from './application';

export default class AnnouncementTemplateSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
    updatedAt: {
      serialize: false,
    },
  };

  serialize(...args: Parameters<ApplicationSerializer['serialize']>): SinglePayload<AnnouncementTemplateModel> {
    const payload = <SinglePayload<AnnouncementTemplateModel>>super.serialize(...args);
    const defaultEmployeeGroups = payload?.data?.attributes['default-employee-groups'];

    if (defaultEmployeeGroups?.length) {
      delete payload.data.attributes['default-employee-groups'];

      payload.data.relationships['employee-groups'] = {
        data: defaultEmployeeGroups.map((id) => ({
          id,
          type: 'employee-groups',
        })),
      };
    }

    return payload;
  }
}
