import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type EmployeeModel from './employee';
import type EntryModel from './entry';
import type InviteModel from './invite';
import type LocationModel from './location';
import type ReservationModel from './reservation';
import type ZoneModel from './zone';

@adapter('badge-log')
class AccessEventModel extends Model {
  // attributes
  @attr('string') declare eventId: string;
  @attr('string') declare integrationName: string;
  @attr('string') declare integrationType: string;
  @attr('date') declare messageTimestamp: Date;
  @attr('date') declare receivedAtTimestamp: Date;
  @attr('string') declare personEmail: string;
  @attr('string') declare personType: string;
  @attr('string') declare personName?: string;
  @attr('string') declare siteId: string;
  @attr('string') declare employeeId?: string;
  @attr('number') declare entryId?: number;
  @attr('number') declare inviteId?: number;
  @attr('number') declare reservationId?: number;
  @attr('number') declare deliveryAttempt: number;
  @attr('string') declare failureReason?: string;

  // relationships
  @belongsTo('entry') declare entry?: AsyncBelongsTo<EntryModel>;
  @belongsTo('invite') declare invite?: AsyncBelongsTo<InviteModel>;
  @belongsTo('reservation') declare reservation?: AsyncBelongsTo<ReservationModel>;
  @belongsTo('zone') declare zone?: AsyncBelongsTo<ZoneModel>;
  @belongsTo('location') declare location?: AsyncBelongsTo<LocationModel>;
  @belongsTo('employee') declare employee?: AsyncBelongsTo<EmployeeModel>;
}

export default AccessEventModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'access-event': AccessEventModel;
  }
}
