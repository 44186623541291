import { useRef } from 'react';

import { GenericService } from '../types';

const EmployeeGroupType = {
  CUSTOM_SCIM_GROUP: 'Employee Group',
  DEPARTMENT: 'Department',
  DIVISION: 'Division',
  ORGANIZATION: 'Organization',
};

export const useCustomEmployeeOptions = (
  service: Pick<GenericService, 'getEmployeeGroupOptions' | 'getEmployeeOptions'>,
  // canSelectScimGroups?: boolean,
) => {
  const groupPage = useRef(0);
  const employeePage = useRef(0);
  const page = useRef(0);
  // const groupHasMore = useRef(canSelectScimGroups ? true : false);
  const groupHasMore = useRef(true);
  // const employeeHasMore = useRef(true);
  const employeeHasMore = useRef(false);
  const query = useRef('');

  const fetchGroups = async () => {
    const { results, hasMore } = await service.getEmployeeGroupOptions(query.current, groupPage.current);

    const groupOptions = () =>
      results.map((option) => ({
        ...option,
        label: `${option.label} (${EmployeeGroupType[option.type as keyof typeof EmployeeGroupType]}) (${option.memberCount})`,
      }));

    return {
      results: groupOptions(),
      hasMore,
    };
  };

  // const fetchEmployees = async () => {
  //   const { results, hasMore } = await service.getEmployeeOptions(query.current, employeePage.current);

  //   return {
  //     results,
  //     hasMore,
  //   };
  // };

  const loadCustomEmployeeOptions = async (currQuery: string) => {
    const options = [];

    if (currQuery !== query.current) {
      groupPage.current = 0;
      employeePage.current = 0;
      groupHasMore.current = true;
      // groupHasMore.current = canSelectScimGroups ? true : false;
      employeeHasMore.current = false;
      // employeeHasMore.current = true;
      page.current = 0;
      query.current = currQuery;
    }

    if (groupHasMore.current) {
      const { results, hasMore } = await fetchGroups();

      groupHasMore.current = hasMore;
      groupPage.current += 1;

      options.push({
        label: 'Groups',
        options: results,
      });
    }

    // if (employeeHasMore.current && !groupHasMore.current) {
    //   const { results, hasMore } = await fetchEmployees();

    //   employeeHasMore.current = hasMore;
    //   employeePage.current += 1;

    //   options.push({
    //     label: 'Employees',
    //     options: results,
    //   });
    // }

    const hasMore = groupHasMore.current || employeeHasMore.current;

    page.current += 1;

    return {
      options,
      hasMore,
    };
  };

  return {
    loadCustomEmployeeOptions,
  };
};
