import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from 'macro-decorators';
import { task } from 'ember-concurrency';
import urlBuilder from 'garaje/utils/url-builder';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Array}               invites
 * @param {Array}               printers
 * @param {Function}            onSuccess
 * @param {Function}            close
 */
export default class InviteSignInModal extends Component {
  @service ajax;
  @service flashMessages;
  @service metrics;
  @service store;
  @service state;

  @alias('state.desksSubscription') desksSubscription;

  @tracked date = new Date();
  @tracked printBadge = true;
  @tracked notification = true;
  @tracked notificationDisabled = false;
  @tracked selectedPrinter = null;

  get isSaving() {
    return this.saveSignIn.isRunning;
  }

  get canSelectPrinters() {
    return this.printBadge && this.args.printers.length > 1;
  }

  get numVisitors() {
    const totalAdditionalGuests = this.args.invites.reduce((count, invite) => {
      return count + get(invite, 'additionalGuests');
    }, 0);
    return this.args.invites.length + totalAdditionalGuests;
  }

  @task
  *saveSignIn(data) {
    try {
      const result = yield this.ajax.request(urlBuilder.v2.entryBulkUrl(), {
        type: 'POST',
        contentType: 'application/json',
        data,
      });
      this._trackInvitesBulkSignIn(result.data.length);
      this.store.pushPayload('entry', result);
      this.flashMessages.showAndHideFlash('success', 'Signed in!');
      if (this.args.onSuccess) {
        this.args.onSuccess();
      }
      this.args.close();
      const isAllowed = !!this.desksSubscription?.isUsable && !!this.state.currentLocationEnabledDesks?.active;
      const checkInData = this.entriesCheckInData(result.data);
      if (isAllowed && checkInData.length > 0) {
        yield this.entriesCheckIn(checkInData);
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      throw e;
    }
  }

  async entriesCheckIn(data) {
    await this.ajax.request(urlBuilder.rms.bulkEntryCheckIn(), {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: data,
    });
  }

  _trackInvitesBulkSignIn(invite_count) {
    this.metrics.trackEvent('Invites Bulk Sign in', { invite_count });
  }

  @action
  signIn() {
    const {
      selectedPrinter,
      date,
      printBadge,
      notification,
      args: { invites },
    } = this;
    const ids = invites.mapBy('id');
    const data = {
      'invite-ids': ids,
      'finalized-at': date.toISOString(),
      'print-badges': printBadge,
      'printer-id': selectedPrinter?.id,
      'notify-host': notification,
    };
    this.saveSignIn.perform(data);
  }

  entriesCheckInData(entries) {
    const data = entries.reduce((data, entry) => {
      if (entry.attributes.employeeScreeningFlow) {
        data.push({
          data: {
            attributes: {
              'entry-id': entry.id,
              'invite-id': entry.relationships.invite.data.id,
              'location-id': entry.relationships.location.data.id,
            },
          },
        });
      }
      return data;
    }, []);
    return data;
  }
}
