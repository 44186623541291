/* eslint-disable ember/no-computed-properties-in-native-classes */
import { and, readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_ACTIVATE_ROLES = Object.freeze([GLOBAL_ADMIN]);

const CAN_ACTIVATE_DURING_TRIAL_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN]);

const CAN_CREATE_ROLES = Object.freeze([GLOBAL_ADMIN]);

const CAN_CREATE_DURING_TRIAL_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN]);

const CAN_EDIT_DEVICE_SETTINGS_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_EDIT_NOTIFICATION_SETTINGS_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_EDIT_PICK_UP_SETTINGS = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_VISIT_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST]);

export default class DeliveryAreaAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  @readOnly('canActivate') canDisable!: boolean;
  @readOnly('state.deliveriesSubscription') deliveriesSubscription!: StateService['deliveriesSubscription'];
  @and('deliveriesSubscription.isOnTrial', 'deliveriesSubscription.hasTrialDaysLeft') isActiveDeliveriesTrial!: boolean;
  @readOnly('deliveriesSubscription.isBasicUser') isBasicUser!: boolean;
  @readOnly('deliveriesSubscription.isBasicPlan') isDeliveriesBasic!: boolean;

  get canActivate(): boolean {
    if (this.deliveriesSubscription && this.deliveriesSubscription.onExpiredTrial) {
      return false;
    }

    const { roleNames: roles } = this.currentAdmin;

    const canActivateRoles = CAN_ACTIVATE_ROLES;
    const canActivateDuringTrialRoles = CAN_ACTIVATE_DURING_TRIAL_ROLES;

    if (this.isActiveDeliveriesTrial) {
      return isPresent(_intersection(canActivateDuringTrialRoles, roles));
    } else {
      return isPresent(_intersection(canActivateRoles, roles));
    }
  }

  get canCreate(): boolean {
    if (this.isDeliveriesBasic) {
      return true;
    }

    if (this.isBasicUser) {
      return false;
    }

    const { roleNames: roles } = this.currentAdmin;

    const canCreateRoles = CAN_CREATE_ROLES;
    const canCreateDuringTrialRoles = CAN_CREATE_DURING_TRIAL_ROLES;

    if (this.isActiveDeliveriesTrial) {
      return isPresent(_intersection(canCreateDuringTrialRoles, roles));
    } else {
      return isPresent(_intersection(canCreateRoles, roles));
    }
  }

  get canGetStarted(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_CREATE_ROLES, roles));
  }

  get canEditNotificationSettings(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_NOTIFICATION_SETTINGS_ROLES, roles));
  }

  get canEditDeviceSettings(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_DEVICE_SETTINGS_ROLES, roles));
  }

  get canEditPickUpSettings(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_PICK_UP_SETTINGS, roles));
  }

  get canVisit(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_ROLES, roles));
  }
}
