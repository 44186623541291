/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import { dropTask, task } from 'ember-concurrency';
import moment from 'moment-timezone';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class SubscriptionBoxV2 extends Component {
  @service flashMessages;
  @service metrics;
  @service store;
  @service featureFlags;
  @service router;
  @service statsig;

  confirmPurchaseCTAText = 'Confirm purchase';
  limitExceededMessage = 'Upgrade to Deliveries Premium to scan unlimited packages.';
  manageSubscriptionCTAText = 'Manage plan';

  @equal('app', 'deliveries') isDeliveries;
  @equal('app', 'rooms') isRooms;
  @equal('app', 'desks') isDesks;
  @equal('app', 'empxp') isWorkplace;
  @equal('app', 'visitors') isVisitors;
  @equal('app', 'virtual front desk') isVfd;
  @alias('fetchPlanTask.lastSuccessful.value') plan;

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.fetchPlanTask.perform();
  }

  get shouldShowContacts() {
    return this.featureFlags.isEnabled('billing_page_show_contacts') && this.contacts.length;
  }

  get shouldShowFreeBundle() {
    return this.featureFlags.isEnabled('growth_workplace_free_bundle') && this.subscription?.freeBundle;
  }

  @computed('subscription.unitType')
  get addQuantityCTAText() {
    return `Purchase ${pluralize(this.subscription.unitType)} →`;
  }

  @computed('subscription.nextPeriod', 'yearlySavings')
  get billingPeriodCTAText() {
    if (this.subscription.nextPeriod === 'yearly') {
      return 'Manage your billing period →';
    } else {
      return `Save ${this.yearlySavings} with yearly billing →`;
    }
  }

  @computed('blockSelfServe', 'subscription.{isBasicPlan,paused,hasScheduledChanges,nonRenewing}')
  get canAddQuantity() {
    return (
      !this.blockSelfServe &&
      !this.subscription.isBasicPlan &&
      !this.subscription.paused &&
      !this.subscription.hasScheduledChanges &&
      !this.subscription.nonRenewing
    );
  }

  @computed(
    'blockSelfServe',
    'subscription.{cancelled,isBasicPlan,nonRenewing,onExpiredTrial,onTrial,paused,hasScheduledChanges,specialPricing,nextPeriod}',
  )
  get canChangeBillingPeriod() {
    return (
      this.subscription &&
      !this.blockSelfServe &&
      !this.subscription.isBasicPlan &&
      !this.subscription.nonRenewing &&
      !this.subscription.cancelled &&
      !this.subscription.onTrial &&
      !this.subscription.onExpiredTrial &&
      !this.subscription.paused &&
      !this.subscription.hasScheduledChanges &&
      (!this.subscription.specialPricing || this.subscription.nextPeriod === 'yearly') &&
      !this.isWorkplace &&
      !this.isVfd
    );
  }

  @computed('blockSelfServe', 'subscription.{subscribed,hasScheduledChanges,nonRenewing}')
  get canCancelSchedule() {
    return (
      !this.blockSelfServe &&
      this.subscription?.subscribed &&
      (this.subscription?.hasScheduledChanges || this.subscription?.nonRenewing)
    );
  }

  @computed('limit.{monthlyLimit,monthlyLimitUsage,resumeAt}')
  get enforceLimitMessage() {
    return `Packages: ${this.limit.monthlyLimitUsage}/${this.limit.monthlyLimit},\nlimit resets ${moment(
      this.limit.resumeAt,
    ).format('MMMM D, YYYY')}`;
  }

  get trialDaysLeft() {
    return `${pluralize(this.subscription.trialDaysLeft, 'day')} left`;
  }

  @computed(
    'blockSelfServe',
    'canChangeBillingPeriod',
    'isRooms',
    'isDesks',
    'subscription.{cancelled,cancelledAt,downgradePlan.normalizedPlanLevel,isBasicPlan,isDowngrading,nextPeriod,nonRenewing,onExpiredTrial,onTrial,period,periodEndDate,trialDaysLeft,trialEndDate}',
  )
  get statusAndPeriodDetails() {
    if (!this.subscription) {
      return '';
    }

    const app = capitalize(this.appName || this.app);
    const cancelledAt = moment(this.subscription.cancelledAt).format('MMM D, YYYY');
    if (this.subscription.freeBundle) {
      return `Enjoy 1-year of ${app}, for free. Your complimentary subscription automatically ends on ${cancelledAt}.`;
    } else if (this.subscription.onTrial) {
      return `You have ${this.trialDaysLeft} in your ${app} ${capitalize(
        this.subscription.normalizedPlanName || '',
      )} trial.`;
    } else if (this.subscription.onExpiredTrial) {
      if (this.blockSelfServe) {
        return `Your ${app} trial is over. Contact us to continue using ${app}.`;
      } else {
        return `Your ${app} trial is over. Choose a plan to continue using ${app}.`;
      }
    } else if (!this.subscription.isBasicPlan) {
      const nextBillingAt = moment(this.subscription.nextBillingAt).format('MMM D, YYYY');
      const periodEndDate = moment(this.subscription.periodEndDate).format('MMM D, YYYY');
      let details;

      if (this.subscription.isDowngrading) {
        details = `Your ${app} plan will downgrade to ${capitalize(
          get(this, 'subscription.downgradePlan.normalizedPlanLevel') ?? '',
        )} on ${nextBillingAt}.`;
      } else if (this.subscription.cancelled) {
        details = `Your ${app} plan was canceled on ${cancelledAt}. Purchase a plan to continue using ${app}`;
      } else if (this.subscription.nonRenewing) {
        details = `Your ${app} plan will be cancelled on ${cancelledAt}.`;
      } else if (this.subscription.nextPeriod !== this.subscription.period) {
        details = `Your ${this.subscription.period} plan switches to ${this.subscription.nextPeriod} on ${periodEndDate}.`;
      } else {
        details = `Your ${this.subscription.period} plan will be automatically renewed on ${periodEndDate}.`;
      }

      return details;
    } else {
      return null;
    }
  }

  @computed(
    'activeQuantity',
    'canAddQuantity',
    'isDeliveries',
    'isRooms',
    'isVisitors',
    'isDesks',
    'subscription.{cancelled,isBasicPlan,isDeliveries,quantity,unitType}',
  )
  get quantityDetails() {
    if (!this.subscription.cancelled && !(this.isVisitors && this.subscription.isBasicPlan)) {
      const unitType = `${pluralize(this.subscription.unitType)}`;
      const paidStatus = this.subscription.isBasicPlan ? 'free' : 'paid';
      const paidQuantity = !(this.subscription.isBasicPlan && (this.isDeliveries || this.isRooms))
        ? `${this.subscription.quantity || '0'} ${!this.isWorkplace ? paidStatus : ''}`
        : '';
      let activeQuantity = this.isDesks
        ? `${this.subscription.maximumDesksForCurrentPlan} desks`
        : `${this.activeQuantity} active`;

      if (this.isWorkplace) {
        activeQuantity = '';
      }
      if (paidQuantity) {
        if (this.isWorkplace) {
          return `${paidQuantity}${pluralize(unitType)}`;
        }
        return `${paidQuantity} ${unitType}, ${activeQuantity}`;
      } else {
        return `${capitalize(unitType)}: ${activeQuantity}`;
      }
    } else {
      return null;
    }
  }

  @computed('plan.{monthlyPrice,yearlyPrice}', 'subscription.quantity')
  get yearlySavings() {
    if (!this.plan || !this.subscription) {
      return null;
    }
    const priceDifferential = this.plan.monthlyPrice - this.plan.yearlyPrice;
    const yearlySavings = priceDifferential * 12 * this.subscription.quantity;
    return `$${yearlySavings}`;
  }

  @task
  *fetchPlanTask() {
    try {
      if (this.subscription && this.subscription.normalizedPlanId) {
        return yield this.store.findRecord('plan', this.subscription.normalizedPlanId);
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  ctaTrackingEventOptions(clickableText) {
    let limitMessage;

    if (this.isDeliveries && !this.onTrial && !this.onExpiredTrial && this.limit) {
      if (this.limit.enforceLimit) {
        limitMessage = `${this.enforceLimitMessage}`;
      } else if (this.limit.limitExceeded) {
        limitMessage = limitMessage + ` ${this.limitExceededMessage}`;
      }
    }

    const messageBody = limitMessage ? `${this.quantityDetails} ${limitMessage}` : this.quantityDetails;

    return {
      product: this.app,
      cta_id: 'manage_subscription',
      cta_type: 'card',
      cta_clickable_type: 'button',
      cta_clickable_text: clickableText,
      cta_title: this.statusAndPeriodDetails,
      cta_body: messageBody,
    };
  }

  @dropTask
  *cancelScheduledTask() {
    set(this.subscription, 'downgradePlan', null);
    try {
      yield this.subscription.save();
      this.flashMessages.showAndHideFlash('success', 'Scheduled changes canceled');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action trackManageSubscriptionViewed(element) {
    this.metrics.trackEvent('CTA Viewed', this.ctaTrackingEventOptions(element.innerText));
  }

  @action
  trackManageSubscriptionClicked(event) {
    this.metrics.trackEvent('CTA Clicked', this.ctaTrackingEventOptions(event.currentTarget.innerText));
  }

  @action redirectToPurchaseVfd() {
    let currentBillingState;
    if (this.subscription?.onTrial) {
      currentBillingState = 'in trial';
    } else if (this.subscription?.onExpiredTrial) {
      currentBillingState = 'expired trial';
    } else if (this.subscription?.cancelled) {
      currentBillingState = 'cancelled';
    }
    this.statsig.logEvent('vfd-purchase-button-clicked', null, { currentBillingState, from: 'billing' });
    this.router.transitionTo('billing.subscribe', {
      queryParams: { plan: 'vfd-standard', ref: 'billing-page' },
    });
  }

  @action
  openIntercom(eventName) {
    if (eventName) {
      this.statsig.logEvent(eventName);
    }
    try {
      window.Intercom('show');
    } catch {
      window.open('mailto:' + 'hi@envoy.com', '_blank');
    }
  }
}
