import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { formatInTimeZone } from 'date-fns-tz';
import { didCancel } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type LocationsCapacityModel from 'garaje/models/locations-capacity';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import { hash } from 'rsvp';

import type WorkplaceAccessEventsController from '../controller';
import type { WorkplaceAccessEventsRouteModel, WorkplaceAccessEventsRouteParams } from '../route';

export interface WorkplaceAccessEventsIndexRouteModel {
  currentLocation: LocationModel;
  accessEvents: WorkplaceAccessEventsRouteModel;
  locationsCapacity: LocationsCapacityModel | null;
  workplaceSubscription: LocationSubscriptionModel | SubscriptionModel | null;
}

class WorkplaceAccessEventsIndexRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = '';

  async model(): Promise<WorkplaceAccessEventsIndexRouteModel> {
    const { currentLocation, workplaceSubscription } = this.state;
    const { capacityLimitEnabled, timezone } = currentLocation;
    const accessEvents = <WorkplaceAccessEventsRouteModel>this.modelFor('workplace.access-events');
    const today = formatInTimeZone(new Date(), timezone, DATE_FNS_YYYY_MM_DD);

    let { date, startDate, endDate } = <WorkplaceAccessEventsRouteParams>this.paramsFor('workplace.access-events');

    startDate = startDate ? formatInTimeZone(new Date(startDate), timezone, DATE_FNS_YYYY_MM_DD) : today;
    endDate = endDate ? formatInTimeZone(new Date(endDate), timezone, DATE_FNS_YYYY_MM_DD) : today;
    date = date || (startDate === endDate ? startDate : today);

    let locationsCapacity: Promise<LocationsCapacityModel> | null = null;

    if (capacityLimitEnabled) {
      locationsCapacity = <Promise<LocationsCapacityModel>>(<unknown>fetchCapacity(this.store, currentLocation, date));
    }

    return hash({
      currentLocation,
      accessEvents,
      locationsCapacity,
      workplaceSubscription,
    });
  }

  afterModel(model: WorkplaceAccessEventsIndexRouteModel): void | Promise<unknown> {
    const accessEvents = model.accessEvents;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <WorkplaceAccessEventsController>this.controllerFor('workplace.access-events');

    const props = {
      refresh: '',
      page: 0,
      limit: 25,
      totalLoadedAccessEvents: 0,
      date: accessEvents.date,
      query: accessEvents.query,
      filter: accessEvents.filter,
      sort: accessEvents.sort,
    };

    setProperties(controller, props);

    return controller.loadAccessEvents.perform().catch((e) => {
      if (!didCancel(e)) {
        throw e;
      }
      return this.router.transitionTo('workplace.access-events');
    });
  }
}

export default WorkplaceAccessEventsIndexRoute;
